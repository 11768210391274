import React from "react";
import "./ServiceMainThree.scss";
import ServiceMain3 from "../../images/ServiceMain3.png";
import { useTranslation } from "react-i18next";

const ServiceMainThree = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="service-main-three-wrapper">
        <div className="service-main-three-img">
          <img src={ServiceMain3} />
        </div>
        <div className="service-main-three-intro">
          <div className="service-main-three-upper-div"></div>
          <div className="service-main-three-content">
            <h2>{t('ServiceMainThree.1')}</h2>
            <p>
            {t('ServiceMainThree.2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceMainThree;
