import React, { useState } from "react";
import "./Navbar.scss";
import Anda_Logo_50 from "../NavBar/Anda_Logo_50.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import { TbX } from "react-icons/tb";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  function languageClick(languages) {
    i18n.changeLanguage(languages);
  }

  return (
    <div className="nav-container">
      <div className="nav-logo">
        <Link to="/">
          <img src={Anda_Logo_50} />
        </Link>
      </div>
      <div className="nav-menu">
        <ul className={click ? "nav-menu-items active" : "nav-menu-items"}>
          <div className="lang-menu-mobile">
            <ul className="lang-switch">
              <li className="eng-btn">
                <NavLink to="" type="submit" style={{ color: i18n.resolvedLanguage === 'en' ? '#f77b00' : '' }}  onClick={() => languageClick("en")}>
                  <p >EN</p>
                </NavLink>
              </li>
              <p>
                <li>|</li>
              </p>
              <li className="eng-btn">
                <NavLink to=""  onClick={() => languageClick("fr")}>
                  <p>FR</p>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/">{t("Navitem.1")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/About">{t("Navitem.2")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/Services">{t("Navitem.3")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/Shipping">{t("Navitem.4")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/News">{t("Navitem.5")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/Management">{t("Navitem.6")}</NavLink>
            </li>
          </div>
          <div className="hr-bottom-line">
            <li className="nav-item-wrap">
              <NavLink to="/Contact">{t("Navitem.7")}</NavLink>
            </li>
          </div>
        </ul>
        <div className="lang-menu">
          <ul className="lang-switch">
            <li>
              <NavLink to="" onClick={() => languageClick("en")}>
                <p>EN</p>
              </NavLink>
            </li>
            <p>
              <li>|</li>
            </p>
            <li>
              <NavLink onClick={() => languageClick("fr")}>
                <p>FR</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="toggle" onClick={handleClick}>
          {click ? (
            <TbX size="3rem" color="black" />
          ) : (
            <HiMenuAlt3 size="3rem" color="black" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
