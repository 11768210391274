import React from "react";
import "./MarketingHome.scss";
import { Link } from "react-router-dom";
import MarketingHome1 from "../../images/MarketingHome1.png";
import { useTranslation } from "react-i18next";

const MarketingHome = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="marketing-home-container">
      <div className="marketing-home-wrapper">
        <div className="marketinghome-text">
          <h2>{t("MarketingHome.1")}</h2>
          <p>{t("MarketingHome.2")}</p>
          <div className="marketing-home-btn">
            <Link to="/Contact" className="btn">
              {t("MarketingHome.3")}
            </Link>
          </div>
        </div>
        <div className="marketing-home-img">
          <img src={MarketingHome1} alt="" />
        </div>
        <div className="marketing-home-bottom"></div>
      </div>
    </div>
  );
};

export default MarketingHome;
