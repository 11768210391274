import React, { Component } from "react";
import "./ContactForm.scss";
import { ReactDOM } from "react";
import { withTranslation } from 'react-i18next';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      isError: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });

    const res = await fetch(
      "https://lyqstyug66.execute-api.eu-west-1.amazonaws.com/prod/anda-contact",
      {
        method: "POST",
        body: JSON.stringify(this.state.values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { name: "", email: "", message: "" },
        }),
      1600
    );
  };

  handleInputChange = (e) =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });

  render() {
    const { t } = this.props;

    return (
      <div className="form">
        <form onSubmit={this.submitForm} className="form-content">
          <input
            type="text"
            name="name"
            placeholder={t("Contact.3")}
            id="name"
            value={this.state.values.name}
            onChange={this.handleInputChange}
            title="name"
            required
          />

          <input
            type="email"
            name="email"
            placeholder={t("Contact.4")}
            id="email"
            value={this.state.values.email}
            onChange={this.handleInputChange}
            title="Email"
            required
          />

          <textarea
            type="text"
            placeholder={t("Contact.5")}
            name="message"
            id="message"
            value={this.state.values.message}
            onChange={this.handleInputChange}
            title="message"
            required
          />

          <div>
            <button type="submit" className="btn">
            {t("Contact.6")}
            </button>
          </div>
        </form>
        <div className={`message ${this.state.isError && "error"}`}>
          {this.state.isSubmitting ? "Submitting..." : this.state.message}
        </div>
      </div>
    );
  }
}

export default  withTranslation()(Form);
