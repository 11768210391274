import React from "react";
import "./TeamBuilding.scss";
import TeamBuildingImg1 from "../../images/TeamBuildingImg1.png";
import TeamBuildingImg2 from "../../images/TeamBuildingImg2.png";
import TeamBuildingImg3 from "../../images/TeamBuildingImg3.png";

const TeamBuilding = () => {
  return (
    <div className="team-building-container">
      <div className="team-building-section-1">
        <div className="team-building-title">
          <h3>Team Building Activities</h3>
        </div>
        <div className="team-building-img1">
        <img src={TeamBuildingImg2} />
        </div>
        <div className="team-building-img2">
        <img src={TeamBuildingImg3} />
        </div>
      </div>
      <div className="team-building-section-2">
        <div className="team-building-img3">
          <img src={TeamBuildingImg1} />
        </div>
      </div>
    </div>
  );
};

export default TeamBuilding;
