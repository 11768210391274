import React from "react";
import "./ServiceMainTwo.scss";
import ServiceMain1 from "../../images/ServiceMain1.png";
import { useTranslation } from "react-i18next";

const ServiceMainTwo = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="service-main-container2">
      <div className="service-section-two">
        <div className="service-section-img2">
          <img src={ServiceMain1} alt="a truck" />
        </div>
        <div className="service-section-text2">
          <div className="service-section-title2">
            <h2>{t("ServiceMainTwo.1")}</h2> <p>{t("ServiceMainTwo.2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceMainTwo;
