import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import Arrows from "./Arrows";
// import sliderImage from "./sliderImage";
import "./Bannerhome.scss";

import BannerHome1 from "../../images/BannerHome1.jpg";
import BannerHome2webp from "../../images/BannerHome2webp.jpg";
import BannerHome3webp from "../../images/BannerHome3webp.webp";
import { useTranslation, Trans } from 'react-i18next';

function Bannerhome(props) {

  const { t, i18n } = useTranslation();

  const sliderImage = [
      {
        heading: t('sliderImage.1-1'),
        title: t('sliderImage.1-2'),
        description:
          t('sliderImage.1-3'),
        urls: BannerHome1,
      },
      {
        heading: t('sliderImage.2-1'),
        title: t('sliderImage.2-2'),
        description:
        t('sliderImage.2-3'),
        urls: BannerHome2webp,
      },
      {
        heading: t('sliderImage.3-1'),
        title: t('sliderImage.3-2'),
        description:
        t('sliderImage.3-3'),
        urls: BannerHome3webp,
      },
    ];

  const len = sliderImage.length - 1;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default Bannerhome;
