import "../../App.css";
import "./_NewsMain.scss";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import img5 from "./img5.png";
import { BsFacebook, BsYoutube, BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

function NewsMain() {
  const { t, i18n } = useTranslation();
  return (
    <div className="NewsMain">
      <div className="header">
        <h2>{t("NewsMain.1")}</h2>
        <p>
          <strong>
          {t("NewsMain.22")}
          </strong>
        </p>
        <p>
        {t("NewsMain.23")}
        </p>
        <p>
        {t("NewsMain.25")}
        </p>
      </div>
      <div className="NewsMain-container">
        <div className="NewsMain-card">
          <div className="newsCard">
            <div>
              {" "}
              <img src={img1} />
            </div>
            <div className="newsCardContent">
              <div className="newCardBody">
                <h4>{t("NewsMain.2")}</h4>
                <p>{t("NewsMain.3")}</p>
              </div>

              <div className="newsCardFooter">
                <div className="newsCardButton">
                  <Link to="/NewsDetail">
                    <span>{t("NewsMain.4")}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
                <div className="newsCardSocial">
                  <span>{t("NewsMain.5")}</span>
                  <span>
                    <BsFacebook />
                  </span>
                  <span>
                    <BsYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="NewsMain-card">
          <div className="newsCard">
            <div>
              {" "}
              <img src={img2} />
            </div>
            <div className="newsCardContent">
              <div className="newCardBody">
                <h4>{t("NewsMain.6")}</h4>
                <p>{t("NewsMain.7")}</p>
              </div>

              <div className="newsCardFooter">
                <div className="newsCardButton">
                  <Link to="/NewsDetail">
                    <span>{t("NewsMain.8")}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
                <div className="newsCardSocial">
                  <span>{t("NewsMain.9")}</span>
                  <span>
                    <BsFacebook />
                  </span>
                  <span>
                    <BsYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="NewsMain-card">
          <div className="newsCard">
            <div>
              {" "}
              <img src={img3} />
            </div>
            <div className="newsCardContent">
              <div className="newCardBody">
                <h4>{t("NewsMain.10")}</h4>
                <p>{t("NewsMain.11")}</p>
              </div>

              <div className="newsCardFooter">
                <div className="newsCardButton">
                  <Link to="/NewsDetail">
                    <span>{t("NewsMain.12")}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
                <div className="newsCardSocial">
                  <span>{t("NewsMain.13")}</span>
                  <span>
                    <BsFacebook />
                  </span>
                  <span>
                    <BsYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="NewsMain-card">
          <div className="newsCard">
            <div>
              {" "}
              <img src={img4} />
            </div>
            <div className="newsCardContent">
              <div className="newCardBody">
                <h4>{t("NewsMain.14")}</h4>
                <p>{t("NewsMain.15")}</p>
              </div>

              <div className="newsCardFooter">
                <div className="newsCardButton">
                  <Link to="/NewsDetail">
                    <span>{t("NewsMain.16")}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
                <div className="newsCardSocial">
                  <span>{t("NewsMain.17")}</span>
                  <span>
                    <BsFacebook />
                  </span>
                  <span>
                    <BsYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="NewsMain-card">
          <div className="newsCard">
            <div>
              {" "}
              <img src={img5} />
            </div>
            <div className="newsCardContent">
              <div className="newCardBody">
                <h4>{t("NewsMain.18")}</h4>
                <p>{t("NewsMain.19")}</p>
              </div>

              <div className="newsCardFooter">
                <div className="newsCardButton">
                  <Link to="/NewsDetail">
                    <span>{t("NewsMain.20")}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </Link>
                </div>
                <div className="newsCardSocial">
                  <span>{t("NewsMain.21")}</span>
                  <span>
                    <BsFacebook />
                  </span>
                  <span>
                    <BsYoutube />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsMain;
