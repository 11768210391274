import react from "react";
import "./App.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Services from "./routes/Services";
import Shipping from "./routes/Shipping";
import News from "./routes/News";
import NewsDetail from "./routes/NewsDetail";
import Management from "./routes/Management";
import Contact from "./routes/Contact";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Shipping" element={<Shipping />} />
        <Route path="/News" element={<News />} />
        <Route path="/NewsDetail" element={<NewsDetail />} />
        <Route path="/Management" element={<Management />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
