import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import NewHero from "../images/NewHero.jpg";
import Footer from "../components/Footer/Footer";
import NewsMain from "../components/NewsMain/NewsMain";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={NewHero}
        intro={t("News.1")}
        title={t("News.2")}
        description=""
      />
      <NewsMain />
      <Footer />
    </div>
  );
};

export default News;
