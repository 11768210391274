import React from "react";
import "./ServiceIntro.scss";
import { GoLocation } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ServiceIntro = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="servic-intro">
      <hr className="service-intro-hr" />
      <div className="service-intro-content">
        <div className="service-intro-header">
          <h2>{t("ServiceIntro.1")}</h2>
        </div>
        <div>
          <p>{t("ServiceIntro.2")}</p>
        </div>
      </div>
      <div className="service-intro-card-container">
        <div className="service-intro-cards">
          <div className="service-intro-icon">
            <GoLocation size="5rem" color="#F77B00" />
          </div>
          <div className="service-intro-card-title">
            <h3>{t("ServiceIntro.3")}</h3>
          </div>
          <div className="service-intro-card-text">
            <p>{t("ServiceIntro.4")}</p>
          </div>
          <NavLink to="" className="service-intro-link">
            {t("ServiceIntro.5")}
          </NavLink>
        </div>
        <div className="service-intro-cards">
          <div className="service-intro-icon">
            <GoLocation size="5rem" color="#F77B00" />
          </div>
          <div className="service-intro-card-title">
            <h3>{t("ServiceIntro.6")}</h3>
          </div>
          <div className="service-intro-card-text">
            <p>{t("ServiceIntro.7")}</p>
          </div>
          <NavLink to="" className="service-intro-link">
            {t("ServiceIntro.8")}
          </NavLink>
        </div>
        <div className="service-intro-cards">
          <div className="service-intro-icon">
            <GoLocation size="5rem" color="#F77B00" />
          </div>
          <div className="service-intro-card-title">
            <h3> {t("ServiceIntro.9")}</h3>
          </div>
          <div className="service-intro-card-text">
            <p>{t("ServiceIntro.10")}</p>
          </div>
          <NavLink to="" className="service-intro-link">
            {t("ServiceIntro.11")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ServiceIntro;
