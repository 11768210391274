import React from "react";
import "./_AboutCarousel.scss";
import "../../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import img1 from "./Picture1.png";
import img2 from "./Picture2.png";
import img3 from "./Picture4.png";
import img4 from "./Picture5.png";
import { useTranslation } from "react-i18next";

const AboutCarousel = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="aboutCarouselContainer">
      <div className="aboutCarouselHeader">
        <h2>{t('AboutCarousel.1')}</h2>
        <p>
          {t('AboutCarousel.2')}
        </p>
      </div>
      <div className="aboutCarouselBody">
        <Carousel>
          <div className="aboutCarousel">
            <img src={img1} />
          </div>
          <div className="aboutCarousel">
            <img src={img2} />
          </div>
          <div className="aboutCarousel">
            <img src={img3} />
          </div>
          <div className="aboutCarousel">
            <img src={img4} />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default AboutCarousel;
