import React from "react";
import "./ServiceMainFive.scss";
import { useTranslation } from "react-i18next";

const ServiceMainFive = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="service-main-five-container">
      <div className="service-main-five-content">
        <div className="service-main-five-promo">
          <hr className="service-main-five-hr" />
          <h2>{t("ServiceMainFive.1")}</h2>
        </div>
        <div className="service-main-five-text">
          <p>{t("ServiceMainFive.2")}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceMainFive;
