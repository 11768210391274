import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import ServiceHero from "../images/ServiceHero.jpg";
import ServiceMainOne from "../components/ServiceMainPage/ServiceMainOne";
import ServiceMainTwo from "../components/ServiceMainPage/ServiceMainTwo";
import ServiceMainThree from "../components/ServiceMainPage/ServiceMainThree";
import ServiceMainFour from "../components/ServiceMainPage/ServiceMainFour";
import Footer from "../components/Footer/Footer";
import ServiceMainFive from "../components/ServiceMainPage/ServiceMainFive";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={ServiceHero}
        intro={t("Services.1")}
        title={t("Services.2")}
        description={t("Services.3")}
      />
      <ServiceMainOne />
      <ServiceMainTwo />
      <ServiceMainFour />
      <ServiceMainFive />
      <ServiceMainThree />
      <Footer />
    </div>
  );
};

export default Services;
