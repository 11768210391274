import React from "react";
import "./../App.css";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import AboutHero from "../images/AboutHero.jpg";
import Footer from "../components/Footer/Footer";
import OurMission from "../components/OurMission/OurMission";
import AboutUs from "../components/AboutUs/AboutUs";
import CoreValue from "../components/CoreValue/CoreValue";
import AboutCarousel from "../components/AboutCarousel/AboutCarousel";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={AboutHero}
        intro= {t("About.1")}
        title={t("About.2")}
        description={t("About.3")}
      />
      <OurMission />
      <AboutUs />
      <CoreValue />
      <AboutCarousel />
      <Footer />
    </div>
  );
};

export default About;
