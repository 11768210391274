import React from "react";
import Navbar from "../components/NavBar/Navbar";
import Bannerhome from "../components/BannerHome/Bannerhome";
import ServiceIntro from "../components/ServiceIntro/ServiceIntro";
import AboutHome from "../components/AboutHome/AboutHome";
import ServiceHome from "../components/ServiceHome/ServiceHome";
import ImageSlider from "../components/ImageSliderShoppingHome/ImageSlider";
import HomeContact from "../components/HomeContact/HomeContact";
import HomeNews from "../components/HomeNews/HomeNews";
import MarketingHome from "../components/MarketingHome/MarketingHome";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Bannerhome />
      <ServiceIntro />
      <AboutHome />
      <ServiceHome />
      <ImageSlider />
      <MarketingHome />
      <HomeNews />
      <HomeContact />
      <Footer />
    </div>
  );
};

export default Home;
