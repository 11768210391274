import React from "react";
import "./OurTeamStyle.scss";
import OurTeamStyle1 from "../../images/OurTeamStyle1.png";
import TeamMeeting from "../../images/TeamMeeting.webp";
import { useTranslation } from "react-i18next";

const OurTeamStyle = () => {
  const { t } = useTranslation();
  return (
    <div className="ourteam-style-container">
      <div className="ourteam-style-wrapper">
        <div className="ourteam-style-img">
          <img src={TeamMeeting} alt="Truck" />
        </div>
        <div className="ourteam-style-content">
          <h2>{t("OurTeamStyle.1")}</h2>
          <p>{t("OurTeamStyle.2")}</p>
        </div>
        {/* <div className="ourstyle-bottom-div"></div> */}
      </div>
    </div>
  );
};

export default OurTeamStyle;
