import React from "react";
import "./ManagementTeam.scss";
import ManagementTeam1 from "../../images/ManagementTeam1.png";
import ManagemntTeam2 from "../../images/ManagemntTeam2.png";
import ManagementTeam3 from "../../images/ManagementTeam3.png";
import ManagementImgPlaceholder from "../../images/ManagementImgPlaceholder.png";
import { useTranslation } from "react-i18next";

const ManagementTeam = () => {
  const { t } = useTranslation();
  return (
    <div className="team-container">
      <div className="team-intro">
        <hr className="team-intro-hr" />
        <h2> {t("ManagementTeam.1")}</h2>
      </div>
      <div className="magt-team-img-container">
        <div className="magt-team-img">
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} className="team-img-wrap" />
            <h4>{t("ManagementTeam.2")}</h4>
            <p>{t("ManagementTeam.3")}</p>
          </div>
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} />
            <h4>{t("ManagementTeam.4")}</h4>
            <p>{t("ManagementTeam.5")}</p>
          </div>
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} />
            <h4>{t("ManagementTeam.6")}</h4>
            <p>{t("ManagementTeam.7")}</p>
          </div>
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} />
            <h4>{t("ManagementTeam.8")}</h4>
            <p>{t("ManagementTeam.9")}</p>
          </div>
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} />
            <h4>{t("ManagementTeam.10")}</h4>
            <p>{t("ManagementTeam.11")}</p>
          </div>
          <div className="magt-team-img1">
            <img src={ManagementImgPlaceholder} />
            <h4>{t("ManagementTeam.12")}</h4>
            <p>{t("ManagementTeam.13")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
