import React from "react";
import "./OurTeam.scss";
import ManagementTeam4 from "./team.jpg";
import LeaderImg from "../../images/LeaderImg.webp"
import { useTranslation } from "react-i18next";

const OurTeam = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="our-team-wrapper">
        <div className="ourteam-intro">
          <div className="ourteam-upper-div"></div>
            <div className="ourteam">
              <h2>{t('OurTeam.1')}</h2>
              <p>{t('OurTeam.2')}
              </p>
            </div>
        </div>
        <div className="ourteam-img">
          <img src={LeaderImg} />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
