import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./_HomeNews.scss";
import { BsFacebook, BsYoutube, BsArrowRight } from "react-icons/bs";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';


class HomeNews extends Component {
  render() {

    const { t } = this.props;

    const settings = {
      className: "center",
      centerMode: false,
      infinite: true,
      centerPadding: "60px",
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="newscardContainer">
        <h2 className="home-news-title">{t('HomeNews.25')}</h2>
        <Slider {...settings}>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image1} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.1')}</h4>
                  <p>{t('HomeNews.2')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <Link to="/NewsDetail">
                      <span>{t('HomeNews.3')}</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.4')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image2} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.5')}</h4>
                  <p>{t('HomeNews.6')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <Link to="/NewsDetail">
                      <span>{t('HomeNews.7')}</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.8')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image3} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.9')}</h4>
                  <p>{t('HomeNews.10')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <Link to="/NewsDetail">
                      <span>{t('HomeNews.11')}</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.12')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image1} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.13')}</h4>
                  <p>{t('HomeNews.14')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <Link to="/NewsDetail">
                      <span>{t('HomeNews.15')}</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.16')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image2} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.17')}</h4>
                  <p>{t('HomeNews.18')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <span>{t('HomeNews.19')}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.20')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="newsCard">
              <div>
                {" "}
                <img src={image3} />
              </div>
              <div className="newsCardContent">
                <div className="newCardBody">
                  <h4>{t('HomeNews.21')}</h4>
                  <p>{t('HomeNews.22')}</p>
                </div>

                <div className="newsCardFooter">
                  <div className="newsCardButton">
                    <span>{t('HomeNews.23')}</span>
                    <span>
                      <BsArrowRight />
                    </span>
                  </div>
                  <div className="newsCardSocial">
                    <span>{t('HomeNews.24')}</span>
                    <span>
                      <BsFacebook />
                    </span>
                    <span>
                      <BsYoutube />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
};
export default  withTranslation()(HomeNews);


