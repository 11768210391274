import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import NewHero from "../images/NewHero.jpg";
import NewsDetailMain from "../components/NewsDetailMain/NewsDetailMain";
import Footer from "../components/Footer/Footer";

const NewsDetail = () => {
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={NewHero}
        intro="Fast and Safe"
        title="DETAIL LATEST NEWS"
        description=""
      />
      <NewsDetailMain />
      <Footer />
    </div>
  );
};

export default NewsDetail;
