import React from "react";
import "./_OurMission.scss";
import "../../App.css";
import { useTranslation } from "react-i18next";

const OurMission = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="ourMissionContainer">
      <div className="ourMissionCol1">
        <div className="ourMissionBody">
          <div className="ourMissionHorizonal">
            <span></span>
          </div>
          <h2>{t("OurMission.1")}</h2>
          <p>{t("OurMission.2")}</p>
        </div>
      </div>
      <div className="ourMissionCol2">
        <div>
          <p>{t("OurMission.3")}</p>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
