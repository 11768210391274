import React from "react";
import "./ServiceHome.scss";
import BusinessHome1 from "../../images/BusinessHome1.png";
import { useTranslation } from "react-i18next";

const ServiceHome = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="business-home-container">
      <div className="business-home-wrapper">
        <div className="businees-home-intro">
          <h2>{t('ServiceHome.1')}</h2>
          <p>
          {t('ServiceHome.2')}
          </p>
        </div>
        <div className="business-home-img">
          <img src={BusinessHome1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ServiceHome;
