import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import ContactHero from "../images/ContactHero.jpg";
import ContactMap from "../components/Map/ContactMap";
import ContactForm from "../components/ContactForm/ContactForm";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={ContactHero}
        intro={t('Contact.1')}
        title={t('Contact.2')}
        description=""
      />
      <ContactForm />
      <ContactMap />
      <Footer />
    </div>
  );
};

export default Contact;
