import React from "react";
import "./ServiceMainFour.scss";
import ServiceMain4 from "../../images/ServiceMain4.png";
import { useTranslation } from "react-i18next";

const ServiceMainFour = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="service-main-four-container">
      <div className="service-main-four-wrapper">
        <div className="service-main-four-intro">
          <div className="service-main-four-upper-div"></div>
          <div className="service-main-four-content">
            <h2>{t("ServiceMainFour.1")}</h2>
            <p>{t("ServiceMainFour.2")}</p>
          </div>
        </div>
        <div className="service-main-four-img">
          <img src={ServiceMain4} />
        </div>
      </div>
    </div>
  );
};

export default ServiceMainFour;
