import React from "react";
import "./ServiceMainOne.scss";
import ServiceMain1 from "../../images/ServiceMain1.png";
import ServiceMain2 from "../../images/ServiceMain2.png";
import { useTranslation } from "react-i18next";

const ServiceMainOne = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="service-main-container">
      <div className="service-section-one">
        <div className="service-section-text">
          <div className="service-section-title">
            <h2>{t('ServiceMainOne.1')}</h2>
            <p>
            {t('ServiceMainOne.2')}
            </p>
          </div>
        </div>
        <div className="service-section-img">
          <img src={ServiceMain2} alt="a truck" />
        </div>
      </div>
    </div>
  );
};

export default ServiceMainOne;
