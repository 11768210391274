import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

function SliderContent({ activeIndex, sliderImage }) {

  const { t, i18n } = useTranslation();

  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <div className="slider-headline-body">
            <img className="slide-image" src={slide.urls} alt="" />
            {/* <div className="slide-headline">
              <hr className="headline-hr-line" />
              <p>{slide.heading}</p>
              <hr className="headline-hr-line" />
              <h1 className="slide-title">{slide.title}</h1>{" "}
              <p className="slide-text">{slide.description}</p>
              <div className="hero-btn">
              <Link to="/Services" className="btn">
                Learn More
              </Link>
              <Link to="/Contact" className="btn btn-light">
                Get a Quote
              </Link>
            </div>
            </div> */}
            <div className="hero-content">
            <div className="header-intro">
              <hr className="headline-hr-line" />
              <p className="home-hero-intro-text">{slide.heading}</p>
              <hr className="headline-hr-line" />
            </div> 
            <h1>{slide.title}</h1>
            <p className="home-hero-text">{slide.description}</p> 
            <div className="hero-btn">
              <Link to="/Services" className="btn">
                {t('sliderImage.4')}
              </Link>
              <Link to="/Contact" className="btn btn-light">
              {t('sliderImage.5')}
              </Link>
            </div>
          </div>

            
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
