import logo from "./logo.png";
import "./_Footer.scss";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutubeSquare,
  FaGlobe,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";

import { BsFacebook, BsYoutube, BsLinkedin } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="col-1">
          <img src={logo} alt="Logo" />
          <div className="icon-container">
            <span className="icon-1" style={{ color: "#0072b1" }}>
              <FaLinkedin />
            </span>
            <span className="icon-2" style={{ color: "#3b5998" }}>
              <BsFacebook />
            </span>
            <span className="icon-3" style={{ color: "#FF0000" }}>
              <BsYoutube />
            </span>
          </div>
        </div>
        <div className="col-2">
          <div className="col-1">
            <h4>{t('Footer.1')}</h4>
            <NavLink to="/" onClick={window.scrollTo(0, 0)}>
            {t('Footer.2')}
            </NavLink>
            <NavLink to="/About" onClick={window.scrollTo(0, 0)}>
            {t('Footer.3')}
            </NavLink>
            <NavLink to="/Services" onClick={window.scrollTo(0, 0)}>
            {t('Footer.4')}
            </NavLink>
            <NavLink to="/Shipping" onClick={window.scrollTo(0, 0)}>
            {t('Footer.5')}
            </NavLink>
            <NavLink to="/News" onClick={window.scrollTo(0, 0)}>
            {t('Footer.6')}
            </NavLink>
            <NavLink to="/Management" onClick={window.scrollTo(0, 0)}>
            {t('Footer.13')}
            </NavLink>
            <NavLink to="/Contact" onClick={window.scrollTo(0, 0)}>
            {t('Footer.7')}
            </NavLink>
          </div>
          <div className="col-2">
            <h4>{t('Footer.8')}</h4>
            <div>
              <div className="row">
                <span>
                  <FaGlobe />
                </span>
                <span>
                {t('Footer.9')}
                </span>
              </div>
              <div className="row">
                <span>
                  <FaPhone />
                </span>
                <span>{t('Footer.10')}</span>
              </div>
              <div className="row">
                <span>
                 {/* <FaEnvelope /> */}
                </span>
                <span>{t('Footer.11')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <p>
          &copy; {t('Footer.12')}
        </p>
      </div>
    </div>
  );
}

export default Footer;
