import React from "react";
import "./ShippingMain.scss";
import ShippingCase1 from "../../images/ShippingCase1.png";
import ShippingCase2 from "../../images/ShippingCase2.png";
import ShippingCase3 from "../../images/ShippingCase3.png";
import ShippingCase4 from "../../images/ShippingCase4.png";
import ShippingCase5 from "../../images/ShippingCase5.png";
import ShippingCase6 from "../../images/ShippingCase6.png";
import ShippingCase7 from "../../images/ShippingCase7.png";
import ShippingCase8 from "../../images/ShippingCase8.png";
import ShippingCase9 from "../../images/ShippingCase9.png";
import { useTranslation } from "react-i18next";

const ShippingMain = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="shipping-main-container">
      <div className="shipping-main-title">
        <hr className="shipping-main-hr" />
        <h2>{t('ShippingMain.1')}</h2>
        <p>{t('ShippingMain.11')}</p>
      </div>
      <div className="shipping-img-wrapper-top">
        <div className="shippingcase-img-wrapper">
          <div className="shipping-case-img">
            <img src={ShippingCase1} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.2')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase2} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.3')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase3} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.4')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase4} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.5')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase5} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.6')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase6} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.7')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase7} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.8')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase8} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.9')}</h4>
            </div>
          </div>
          <div className="shipping-case-img">
            <img src={ShippingCase9} alt="" className="shipping-main-image" />
            <div className="shipping-main-img-text">
              <h4>{t('ShippingMain.10')}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingMain;
