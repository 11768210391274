import React from "react";
import "./ContactMap.scss";

const ContactMap = () => {
  return (
    <div className="map-container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8151142.186197173!2d17.233913934561244!3d-4.0101626311150556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1979facf9a7546bd%3A0x4c63e5eac93f141!2sDemocratic%20Republic%20of%20the%20Congo!5e0!3m2!1sen!2set!4v1670842372921!5m2!1sen!2set"></iframe>
    </div>
  );
};

export default ContactMap;
