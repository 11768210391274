import React from "react";
import "./NewsDetailMain.scss";
import NewsDetail1 from "../../images/NewsDetail1.png";
import NewsDetail2 from "../../images/NewsDetail2.png";
import NewsDetail3 from "../../images/NewsDetail3.png";
import NewsDetail4 from "../../images/NewsDetail4.png";
import { TiMediaPlayReverse } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewsDetailMain = () => {
  const { t } = useTranslation();
  return (
    <div className="news-detail-main-container">
      <div className="news-detail-main-wrapper">
        <div className="news-detail-main-intro">
          <div className="news-detail-main-img">
            <img src={NewsDetail1} />
          </div>
          <div className="news-detail-main-content">
            <h2>{t("NewsDetailMain.1")}</h2>
            <p>{t("NewsDetailMain.2")}</p>
          </div>
          <div className="back-button">
            <Link to="/news">
              <span className="back-button-title">
                {" "}
                {t("NewsDetailMain.3")}
              </span>
              <span className="back-button-icon">
                <TiMediaPlayReverse size="2rem" className="back-button-icon" />{" "}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetailMain;
