import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.scss";
import ShippingHome1 from "../../images/ShippingHome1.jpg";
import ShippingHome2 from "../../images/ShippingHome2.jpg";
import ShippingHome3 from "../../images/ShippingHome3.jpg";
import ShippingHome4 from "../../images/ShippingHome4.jpg";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class SimpleSlider extends Component {
  render() {
    const { t } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        // {
        //   breakpoint: 480,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //   },
        // },
      ],
    };
    return (
      <div className="shipping-home-container">
        <div className="shipping-home-intro">
          <hr className="shipping-intro-hr" />
          <h3>{t("ShippingHome.1")}</h3>
          <p>{t("ShippingHome.2")}</p>
        </div>
        <div>
          <Slider {...settings} className="shipping-case-home">
            <div className="shipping-case1-home">
              <img src={ShippingHome1} className="shipping-home-img" />
              <div className="shipping-img-text">
                <h3>{t("ShippingHome.4")}</h3>
              </div>
            </div>
            <div className="shipping-case1-home">
              <img src={ShippingHome2} className="shipping-home-img" />
              <div className="shipping-img-text">
                <h3>{t("ShippingHome.5")}</h3>
              </div>
            </div>
            <div className="shipping-case1-home">
              <img src={ShippingHome3} className="shipping-home-img" />
              <div className="shipping-img-text">
                <h3>{t("ShippingHome.6")}</h3>
              </div>
            </div>
            <div className="shipping-case1-home">
              <img src={ShippingHome4} className="shipping-home-img" />
              <div className="shipping-img-text">
                <h3>{t("ShippingHome.7")}</h3>
              </div>
            </div>
            <div className="shipping-case1-home">
              <img src={ShippingHome1} className="shipping-home-img" />
              <div className="shipping-img-text">
                <h3>{t("ShippingHome.8")}</h3>
              </div>
            </div>
          </Slider>
          
        </div>
        <div className="shipping-home-btn">
          <Link to="/Shipping" className=" btn btn-light">
          {t("ShippingHome.3")}
          </Link>
        </div>
      </div>
    );
  }
} export default  withTranslation()(SimpleSlider);
