import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import SHippingHero2 from "../images/SHippingHero2.jpg";
import ShippingMain from "../components/ShippingMain/ShippingMain";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const Shipping = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={SHippingHero2}
        intro={t("Shipping.1")}
        title={t("Shipping.2")}
        description={t("Shipping.3")}
      />
      <ShippingMain />
      <Footer />
    </div>
  );
};

export default Shipping;
