import "./_HomeContact.scss";
import homeContactImg from "./homeContactImg.png"
import { useTranslation } from "react-i18next";

function HomeContact() {
    const { t } = useTranslation();
    return <div className="home-contact">
        <div className="home-contact-bg-1"></div>
        <div className="home-contact-col-1">
        <img src={homeContactImg} />       
        </div>
        <div className="home-contact-col-2">
            <div>
            <h2>{t("HomeContact.1")}</h2>
            <p>{t("HomeContact.2")}</p>
            <div className="contact-home-btn"><button className="btn btn-orange">{t("HomeContact.3")}</button></div>
            </div>         
        </div>
  </div>;
}

export default HomeContact;
