import "./HeroImg.scss";
import React, { Component } from "react";

class HeroImg extends Component {
  render() {
    return (
      <div className="hero-container">
        <div className="hero-img-container">
          <img src={this.props.urls} className="hero-img" alt="trucks" />
          <div className="page-hero-content">
            {/* <div className="header-intro">
              <hr className="header-intro-hr" />
              <p>{this.props.intro}</p>
              <hr className="header-intro-hr" />
            </div> */}
            <h1>{this.props.title}</h1>
            {/* <p>{this.props.description}</p> */}
          </div>
        </div>
      </div>
    );
  }
}

export default HeroImg;
