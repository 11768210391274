import React from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroImg from "../components/HeroImg/HeroImg";
import ManageHeroImg from "../images/ManageHeroImg.jpg";
import ManagementTeam from "../components/ManagementPage/ManagementTeam";
import OurTeam from "../components/ManagementOurTeam/OurTeam";
import Footer from "../components/Footer/Footer";
import OurTeamStyle from "../components/OurTeamStyle/OurTeamStyle";
import { useTranslation } from "react-i18next";
import TeamBuilding from "../components/TeamBuilding/TeamBuilding";

const Management = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <HeroImg
        urls={ManageHeroImg}
        intro={t("Management.1")}
        title={t("Management.2")}
        description=""
      />
      <ManagementTeam />
      <OurTeam />
      <OurTeamStyle />
      <TeamBuilding />
      <Footer />
    </div>
  );
};

export default Management;
