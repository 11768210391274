import React from "react";
import "./ContactForm.scss";
import Form from "./Form";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-form-container">
      <div className="contact-container">
        <div className="contact-col-1">
          <div className="form-intro">
            <h2>{t("ContactForm.1")}</h2>
            <p>{t("ContactForm.2")}</p>
          </div>
          <div className="form-content">
            <Form />
          </div>
        </div>
        <div className="contact-col-2">
          <div className="contact-support">
            <h2>{t("ContactForm.3")}</h2>
            <p className="support-text">{t("ContactForm.4")}</p>
            <p className="support-email"><strong>{t("ContactForm.5")}</strong></p>
          </div>
          <div className="contact-address">
            <p>{t("ContactForm.6")}</p>
            <p>{t("ContactForm.7")}</p>
            <p>
              <strong>{t("ContactForm.8")}</strong> {t("ContactForm.9")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
