import React from "react";
import "./_AboutUs.scss";
import "../../App.css";
import image from "./img1.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="aboutUsContainer">
      <div className="aboutUsCol1">
        <div>
          <h2>{t("AboutUs.1")}</h2>
          <p>{t("AboutUs.2")}</p>
        </div>
      </div>
      <div className="aboutUsCol2">
        <img src={image} />
      </div>
    </div>
  );
};

export default AboutUs;
