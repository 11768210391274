import React from "react";
import "./_CoreValue.scss";
import "../../App.css";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import icon3 from "./icon3.png";
import { useTranslation } from "react-i18next";

const CoreValue = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="coreValueContainer">
            <div className="coreValueHeader">
                <h2>{t('CoreValue.1')}</h2>
            </div>
            <div className="coreValueBody">
                <div className="coreValueCard">
                    <img src={icon1} />
                    <h3>{t('CoreValue.2')}</h3>
                    <p>{t('CoreValue.3')}</p>
                </div>

                <div className="coreValueCard">
                    <img src={icon2} />
                    <h3>{t('CoreValue.4')}</h3>
                    <p>{t('CoreValue.5')}</p>
                </div>

                <div className="coreValueCard">
                    <img src={icon3} />
                    <h3>{t('CoreValue.6')}</h3>
                    <p>{t('CoreValue.7')}</p>
                </div>
            </div>
    </div>
    );
};

export default CoreValue;
