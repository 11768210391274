import React from "react";
import "./AboutHome.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutHome = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="about-home-container">
        <div className="about-home-intro">
          <h3>{t("AboutHome.1")}</h3>
          <p>{t("AboutHome.2")}</p>
          <Link to="/About" className="btn ">
            {t("AboutHome.3")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
